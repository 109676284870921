export default [
  {
    path: '/komcards',
    name: 'komcards',
    component: () => import('@/views/pages/komcard/List.vue'),
    meta: {
      resource: 'KartuKomcards',
      action: 'manage',
    },
  },
  {
    path: '/komcards/add',
    name: 'komcards',
    component: () => import('@/views/pages/komcard/Add.vue'),
    meta: {
      resource: 'KartuKomcards',
      action: 'manage',
      navActiveLink: 'komcards',
    },
  },
  {
    path: '/komcards/:id',
    name: 'card-detail',
    component: () => import('@/views/pages/komcard/Detail.vue'),
    meta: {
      resource: 'KartuKomcards',
      action: 'manage',
      navActiveLink: 'komcards',
    },
  },
  {
    path: '/verification-komcard',
    name: 'komcard-verification',
    component: () => import('@/views/pages/komcard/Verification.vue'),
    meta: {
      resource: 'KartuKomcards',
      action: 'manage',
      navActiveLink: 'komcards',
    },
  },
]
