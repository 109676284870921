import Vue from 'vue'
import Toast from 'vue-toastification'
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'

// Toast Notification Component Styles
import '@core/scss/vue/libs/toastification.scss'

/**
 * NOTE: If you are using other transition them make sure to import it in `src/@core/scss/vue/libs/notification.scss` from it's source
 */
Vue.use(Toast, {
  hideProgressBar: true,
  closeOnClick: false,
  closeButton: false,
  icon: false,
  timeout: 3000,
  transition: 'Vue-Toastification__fade',
})

Vue.prototype.$toast_error = (args = { message: '', options: {}, timeout: 2000 }) => {
  Vue.$toast(
    {
      component: ToastificationContentVue,
      props: {
        title: 'Gagal',
        icon: 'AlertCircleIcon',
        text: args.message,
        variant: 'danger',
        ...args.options,
      },
    },
    args.timeout,
  )
}

Vue.prototype.$toast_success = (args = { message: '', options: {}, timeout: 2000 }) => {
  Vue.$toast(
    {
      component: ToastificationContentVue,
      props: {
        title: 'Berhasil',
        icon: 'CheckIcon',
        text: args.message,
        variant: 'success',
        ...args.options,
      },
    },
    args.timeout,
  )
}

Vue.prototype.$toast_warning = (args = { message: '', options: {}, timeout: 2000 }) => {
  Vue.$toast(
    {
      component: ToastificationContentVue,
      props: {
        title: 'Peringatan',
        icon: 'AlertCircleIcon',
        text: args.message,
        variant: 'waring',
        ...args.options,
      },
    },
    args.timeout,
  )
}
